<template>
    <!--编辑页与详情页相同-->
    <div class="SysOutEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="SysOut" label-width="80px" width="auto" :inline="true" size="small">
                    <el-form-item label="机构名称">
                        <el-input readonly v-model="form.deptName" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="save" size="small">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column prop="sku.bars" label="条码" width="180" />
                <el-table-column prop="sku.type" label="类型" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fastBar" label="助记码" width="140" />
                <el-table-column prop="customBar" label="自编码" width="140" />
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.category" label="类目" width="80" />
                <el-table-column prop="sku.unit" label="单位" width="60" />
                <el-table-column prop="sku.brand" label="品牌" width="80" />
                <el-table-column prop="counts" label="出库数量" width="100">
                    <template slot-scope="scope">
                        <ef-price-input
                            v-model="scope.row.counts"
                            :min="1"
                            :precision="0"
                            v-if="scope.row.sku.type == 0"
                        />
                        <ef-price-input v-model="scope.row.counts" :precision="3" v-if="scope.row.sku.type == 1" />
                    </template>
                </el-table-column>
                <el-table-column label="批发价" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.wholeSalePrices | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="零售价" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.retailPrices | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click.native.prevent="deleteRow(scope.$index, tableData)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import MoneyUtils from '../../../../js/MoneyUtils';
import UrlUtils from '../../../../js/UrlUtils';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'SysOutEdit',
    components: { EfPriceInput },
    props: ['form'],
    data() {
        return {
            url: {
                page: '/goods/goods/list_c',
            },
            SysOutInit: {
                deptCode: '',
                deptName: '',
                sysInCode: '',
            },
            codes: [],
            tableData: [],
            paramsData: [],
            SysOutPrice: {},
            bizGoodsArr: [],
            delCodes: [],
        };
    },
    mounted() {
        this.codes = this.form.goodsCodes;
        this.code = this.form.code;

        this.SysOutInit.deptCode = this.form.deptCode;
        this.SysOutInit.deptName = this.form.deptName;
        this.SysOutInit.sysInCode = this.form.sysInCode;
        this.inIt();
    },
    methods: {
        inIt() {
            const _this = this;
            const _arr = {};
            this.form.codes.forEach((v, i) => {
                _arr[this.form.goodsCodes[i]] = {
                    codes: this.form.codes[i],
                    goodsCodes: this.form.goodsCodes[i],
                    sysInDetailCodes: this.form.sysInDetailCodes[i],
                    counts: this.form.counts[i],
                    wholeSalePrices: this.form.wholeSalePrices[i],
                    retailPrices: this.form.retailPrices[i],
                };
            });
            _this.bizGoodsArr = _arr;
            _this.handleQuery();
        },
        deleteRow(index, rows) {
            const detailCode = rows.splice(index, 1)[0].codes;
            this.delCodes.push(detailCode);
        },
        duplicate(arr, type) {
            if (arr.length == 0) {
                return arr;
            } else {
                if (type) {
                    const obj = {};
                    const newArr = arr.reduce((cur, next) => {
                        obj[next.codes] ? '' : (obj[next.codes] = true && cur.push(next));
                        return cur;
                    }, []);
                    return newArr;
                } else {
                    return Array.from(new Set(arr));
                }
            }
        },
        handleQuery() {
            const _this = this;
            const params = {
                params: _this.codes,
                paramsSerializer: function (data) {
                    return data.map((d) => 'codes=' + d).join('&');
                },
            };
            Util.queryTable(_this, _this.url.page, params, (data) => {
                _this.tableData = data.data;
                const _a = [];
                data.data.forEach((value) => {
                    _a.push({
                        sku: value.sku,
                        codes: _this.bizGoodsArr[value.code].codes,
                        goodsCodes: _this.bizGoodsArr[value.code].goodsCodes,
                        wholeSalePrices: _this.bizGoodsArr[value.code].wholeSalePrices,
                        prices: _this.bizGoodsArr[value.code].wholeSalePrices,
                        retailPrices: _this.bizGoodsArr[value.code].retailPrices,
                        counts: _this.bizGoodsArr[value.code].counts,
                        sysInDetailCodes: _this.bizGoodsArr[value.code].sysInDetailCodes,
                    });
                });
                _this.tableData = _a;
            });
        },
        save() {
            const _this = this;

            let vaildCounts = false;
            this.tableData.forEach((v) => {
                if (v.counts == 0 || typeof v.counts == 'undefined') {
                    vaildCounts = true;
                }
            });
            const params = {
                deptCode: this.form.deptCode,
                delCodes: this.delCodes,
                sysInCode: _this.SysOutInit.sysInCode,
                sysInDetailCodes: this.tableData.map((r) => r.sysInDetailCodes),

                codes: this.tableData.map((r) => r.codes || null),
                counts: this.tableData.map((r) => r.counts),
                goodsCodes: this.tableData.map((r) => r.goodsCodes),
                wholeSalePrices: this.tableData.map((r) => r.wholeSalePrices),
                retailPrices: this.tableData.map((r) => r.retailPrices),
                prices: this.tableData.map((r) => MoneyUtils.moneyToDb(r.prices)),
            };
            if (vaildCounts) {
                this.$message.error('库存不能为零且不能为空');
                return;
            }
            UrlUtils.PatchRemote(
                this,
                `/purchase/sysOut/patchExtend/`,
                params,
                null,
                () => {
                    this.goBackAndReload();
                    this.$message.success('保存成功');
                },
                (rst) => {
                    this.$message.error(rst);
                }
            );
        },
    },
    filters: {
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
